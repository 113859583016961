import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';

/**
 * @author tatevik.marikyan
 * @since 27/06/2018
 */

@Component({
    moduleId: module.id + '',
    selector: 'delete-confirm-dialog',
    templateUrl: './delete-confirm-dialog.component.html',
})
export class DeleteConfirmDialogComponent {
    constructor(public dialogRef: MatDialogRef<DeleteConfirmDialogComponent>) {}

    public noAction(): void {
        this.dialogRef.close(false);
    }

    public yesAction(): void {
        this.dialogRef.close(true);
    }
}
