import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AccountingModel} from '@synisys/idm-currency-rate-frontend';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {SisAccountingSettingsComponent} from './sis-accounting-settings.component';
import {Entity} from '@synisys/idm-de-core-frontend';
import {isNil} from 'lodash';

@Component({
               moduleId: module.id + '',
               selector: 'sis-dynamic-accounting',
               templateUrl: 'sis-dynamic-accounting.component.html',
               // changeDetection: ChangeDetectionStrategy.OnPush
           })
@ControlMetadata({
                     name        : 'Accounting',
                     cellCount   : 6,
                     settings    : {
                         main: SisAccountingSettingsComponent,
                     },
                     template    : `
                            <sis-dynamic-accounting
                             [id]="'%{id}'"
                             [entity]="entity"
                             [(accounting)]="%{field}"
                             [isReadonly]="%{isReadonly}"
                             [currencyReadOnly]="%{currencyReadOnly}"
                             [rateDateReadOnly]="%{rateDateReadOnly}"
                             [rateReadOnly]="%{rateReadOnly}"
                             [rateLabel]="%{rateLabel}"
                             [dateLabel]="%{dateLabel}"
                             [parentField]="%{parentField}"
                             [hint]="%{hint}">
                            </sis-dynamic-accounting>
                     `,
                     isFieldBound: true,
                 })
export class SisDynamicAccountingComponent {
    @Output()
    public accountingChange = new EventEmitter<AccountingModel>();
    @Input()
    public entity: Entity;

    private _id: string;
    private _isReadonly: boolean;
    private _accounting: AccountingModel;
    private _rateLabel: string | MultilingualString;
    private _dateLabel: string | MultilingualString;
    private _currencyReadOnly: boolean;
    private _rateDateReadOnly: boolean;
    private _rateReadOnly: boolean;
    private _hint: MultilingualString | undefined;
    private _parentField: string | undefined;

    get id(): string {
        return this._id;
    }

    @Input()
    set id(value: string) {
        this._id = value;
    }

    get currencyReadOnly(): boolean {
      return this._currencyReadOnly;
    }

    @Input()
    set currencyReadOnly(value: boolean) {
      this._currencyReadOnly = value;
    }

    get rateDateReadOnly(): boolean {
      return this._rateDateReadOnly;
    }

    @Input()
    set rateDateReadOnly(value: boolean) {
      this._rateDateReadOnly = value;
    }

    get rateReadOnly(): boolean {
        return this._rateReadOnly;
    }

    @Input()
    set rateReadOnly(value: boolean) {
        this._rateReadOnly = value;
    }

    get dateLabel(): string | MultilingualString {
        return this._dateLabel;
    }

    @Input()
    set dateLabel(value: string | MultilingualString) {
        this._dateLabel = value;
    }

    get rateLabel(): string | MultilingualString {
        return this._rateLabel;
    }

    @Input()
    set rateLabel(value: string | MultilingualString) {
        this._rateLabel = value;
    }

    get accounting(): AccountingModel {
        return this._accounting;
    }

    @Input()
    set accounting(value: AccountingModel) {
        this._accounting = value;
        this.accountingChange.emit(value);
    }

    get isReadonly(): boolean {
        return this._isReadonly;
    }

    @Input()
    set isReadonly(value: boolean) {
        this._isReadonly = value;
    }

    get hint(): MultilingualString | undefined {
        return this._hint;
    }

    @Input()
    set hint(value: MultilingualString | undefined) {
        this._hint = value;
    }

    get parentField(): string | undefined {
        return this._parentField;
    }

    @Input()
    set parentField(value: string | undefined) {
        if (value === '' || isNil(value)) {
            this._parentField = undefined;
        } else {
            this._parentField = value;
        }
    }

    public getParent(): AccountingModel | undefined {
        return (this.entity && this.parentField) ? this.entity.getProperty<AccountingModel>(this.parentField).value :
               undefined;
    }
}

const a = MultilingualString; // for tsc
