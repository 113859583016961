import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlMetadata} from '@synisys/idm-dynamic-controls-metadata';
import {MoneyModel} from '@synisys/idm-currency-rate-frontend';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {SisMoneySettingsComponent} from './sis-money-settings.component';

@Component({
             moduleId       : module.id + '',
             selector       : 'sis-dynamic-money',
             template       : `
                 <sis-money [id]="id" [(money)]="money" [isReadonly]="isReadonly" [hint]="hint">
                 </sis-money>
             `,
             changeDetection: ChangeDetectionStrategy.OnPush
           })
@ControlMetadata({
                   name        : 'Money',
                   cellCount   : 3,
                   settings    : {
                     main: SisMoneySettingsComponent,
                   },
                   template    : `
                            <sis-dynamic-money [id]="'%{id}'" [(money)]="%{field}" [isReadonly]="%{isReadonly}"
                                               [hint]="%{hint}">
                            </sis-dynamic-money>
                     `,
                   isFieldBound: true,
                 })
export class SisDynamicMoneyComponent {

  @Output()
  public moneyChange = new EventEmitter<MoneyModel>();
  private _id: string;
  private _isReadonly: boolean;
  private _money: MoneyModel;
  private _hint: MultilingualString | undefined;

  get id(): string {
    return this._id;
  }

  @Input()
  set id(value: string) {
    this._id = value;
  }

  get isReadonly(): boolean {
    return this._isReadonly;
  }

  @Input()
  set isReadonly(value: boolean) {
    this._isReadonly = value;
  }

  get money(): MoneyModel {
    return this._money;
  }

  @Input()
  set money(value: MoneyModel) {
    this._money = value;
    this.moneyChange.emit(value);

  }

  get hint(): MultilingualString | undefined {
    return this._hint;
  }

  @Input()
  set hint(value: MultilingualString | undefined) {
    this._hint = value;
  }
}

const a = MultilingualString; // for tsc
