import {Component, Input, OnInit} from '@angular/core';
import {
    CommunicationData,
    CommunicationKey,
    CommunicationService,
    ControlMetadata,
} from '@synisys/idm-dynamic-controls-metadata';
import {BaseSettings} from '@synisys/idm-ng-controls/app/controls/control-settings/sis-base-settings';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {multilingualToString, stringToMultilingual} from '../../utilities';
import {TooltipSettings} from '@synisys/idm-ng-controls';
import {
    KbService,
    MetaField,
    MetaFieldType,
} from '@synisys/idm-kb-service-client-js';
import {Observable} from 'rxjs/Observable';
import {first, map} from 'rxjs/operators';
import {InterpreterMetaField} from '@synisys/idm-dynamic-layout-interpreter';
import {notNil} from '@synisys/skynet-store-utilities';

@Component({
    moduleId: module.id + '',
    selector: 'sis-accounting-settings',
    templateUrl: 'sis-accounting-settings.component.html',
})
@ControlMetadata({
    template: `
                      <sis-accounting-settings [state]="%{state}" [languages]="%{languages}"
                                               [fields]="%{fields}"
                                               [currentLanguageId]="%{currentLanguageId}"
                                               [category]="%{category}">
                      </sis-accounting-settings>`,
})
export class SisAccountingSettingsComponent extends BaseSettings
    implements OnInit {
    public disabled = false;

    public accountingFields$: Observable<MetaField[] | undefined>;
    private _parentField: string | undefined;

    @Input()
    public category: string;
    @Input()
    public fields: {field: InterpreterMetaField};

    private _currencyReadOnly: boolean;
    private _rateDateReadOnly: boolean;
    private _rateReadOnly: boolean;
    private _rateLabel: MultilingualString;
    private _dateLabel: MultilingualString;

    get currencyReadOnly(): boolean {
        return this._currencyReadOnly;
    }

    set currencyReadOnly(value: boolean) {
        this._currencyReadOnly = value;
        this._communicationService.parentSubject.next(
            new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                state: {
                    currencyReadOnly: this._currencyReadOnly,
                },
            })
        );
    }

    get rateDateReadOnly(): boolean {
        return this._rateDateReadOnly;
    }

    set rateDateReadOnly(value: boolean) {
        this._rateDateReadOnly = value;
        this._communicationService.parentSubject.next(
            new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                state: {
                    rateDateReadOnly: this._rateDateReadOnly,
                },
            })
        );
    }

    get rateReadOnly(): boolean {
        return this._rateReadOnly;
    }

    set rateReadOnly(value: boolean) {
        this._rateReadOnly = value;
        this._communicationService.parentSubject.next(
            new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                state: {
                    rateReadOnly: this._rateReadOnly,
                },
            })
        );
    }

    get rateLabel(): MultilingualString {
        return this._rateLabel;
    }

    set rateLabel(value: MultilingualString) {
        this._rateLabel = value;
        this._communicationService.parentSubject.next(
            new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                state: {
                    rateLabel: multilingualToString(value),
                },
            })
        );
    }

    get dateLabel(): MultilingualString {
        return this._dateLabel;
    }

    set dateLabel(value: MultilingualString) {
        this._dateLabel = value;
        this._communicationService.parentSubject.next(
            new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                state: {
                    dateLabel: multilingualToString(value),
                },
            })
        );
    }

    get parentField(): string | undefined {
        return this._parentField;
    }

    set parentField(value: string | undefined) {
        this._parentField = value;
        this._communicationService.parentSubject.next(
            new CommunicationData(CommunicationKey.SETTINGS_CHANGED, {
                state: {
                    parentField: value,
                },
            })
        );
    }

    public set state(value: object) {
        const tooltipSettings = value['tooltipSettings'];
        const hint = value['hint'];
        const dateLabel = value['dateLabel'];
        const rateLabel = value['rateLabel'];
        const currencyReadOnly = value['currencyReadOnly'];
        const rateDateReadOnly = value['rateDateReadOnly'];
        const rateReadOnly = value['rateReadOnly'];
        const parentField = value['parentField'];

        if (tooltipSettings) {
            this._tooltipSettings = TooltipSettings.fromJsonString(
                tooltipSettings
            );
        }
        if (hint) {
            this._hint = stringToMultilingual(hint);
        }
        if (dateLabel) {
            this._dateLabel = stringToMultilingual(dateLabel);
        }
        if (rateLabel) {
            this._rateLabel = stringToMultilingual(rateLabel);
        }
        if (notNil(currencyReadOnly)) {
            this._currencyReadOnly = currencyReadOnly;
        }
        if (notNil(rateDateReadOnly)) {
            this._rateDateReadOnly = rateDateReadOnly;
        }
        if (notNil(rateReadOnly)) {
            this._rateReadOnly = rateReadOnly;
        }
        if (parentField) {
            this._parentField = parentField;
        }
    }

    constructor(
        communicationService: CommunicationService,
        private kbService: KbService
    ) {
        super(communicationService);
    }

    public ngOnInit(): void {
        this.accountingFields$ = this.kbService
            .getMetaFields(this.category)
            .pipe(
                first(),
                map(metaFields =>
                    metaFields
                        .filter(
                            metaField =>
                                metaField.getType() === MetaFieldType.ACCOUNTING
                        )
                        .filter(
                            metaField =>
                                metaField.getSystemName() !==
                                this.fields.field.metaFieldId.systemName
                        )
                ),
                map(metaFields => {
                    if (metaFields.length === 0) {
                        return undefined;
                    }
                    return metaFields;
                })
            );
    }
}
