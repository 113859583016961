import {NgModule} from '@angular/core';
import {
    SisDynamicMoneyComponent,
    SisMoneySettingsComponent,
} from './sis-dynamic-money';
import {
    CurrencyRateModule,
    SisAccountingComponent,
    SisMoneyComponent,
} from '@synisys/idm-currency-rate-frontend';
import {
    SisAccountingSettingsComponent,
    SisDynamicAccountingComponent,
} from './sis-dynamic-accounting';
import {ControlsModule} from '@synisys/idm-ng-controls';
import {MatCheckboxModule} from '@angular/material';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {
    SisTranslateModule,
    SisUtilitiesModule,
} from '@synisys/idm-frontend-shared';

@NgModule({
    declarations: [
        SisDynamicMoneyComponent,
        SisMoneySettingsComponent,
        SisDynamicAccountingComponent,
        SisAccountingSettingsComponent,
    ],
    exports: [
        SisAccountingComponent,
        SisMoneyComponent,
        SisDynamicMoneyComponent,
        SisMoneySettingsComponent,
        SisDynamicAccountingComponent,
        SisAccountingSettingsComponent,
    ],
    imports: [
        CurrencyRateModule,
        ControlsModule,
        MatCheckboxModule,
        FormsModule,
        SisUtilitiesModule,
        CommonModule,
        FormsModule,
        SisTranslateModule,
    ],
})
export class SisDynamicCurrencyModule {}
